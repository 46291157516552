import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Cookies from 'js-cookie';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import  './Modalstyles.scss';

const AGE_VERIFICATION_COOKIE_NAME = "age_verified";
const AGE_VERIFICATION_EXPIRY_DAYS = 30;

export default function AgeVerificationOverlay() {
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    const isAgeVerified = Cookies.get(AGE_VERIFICATION_COOKIE_NAME);
    if (isAgeVerified) {
      setOpen(false);
    }
  }, []);

  const handleYes = () => {
    // Allow the user to continue if they are over 21
    //alert("Welcome! You are over 21 and can proceed.");
 
    // Should prevent from opening for 30 days if already clicked yes
    Cookies.set(AGE_VERIFICATION_COOKIE_NAME, true, { expires: AGE_VERIFICATION_EXPIRY_DAYS });

    setOpen(false);
  }

  const handleNo = () => {
    // Redirect the user to Google if they are not over 21
    window.location.replace("https://www.google.com");
    setOpen(true);
  }

  return (
        <Modal open={open} className="modalContainer">
            
        <div className="modal_main">
         <div className="bg"> 
          {/* <h1 className="modal_warning">STOP!</h1> */}
          <h2 className="modal_header_title">ID CHECK</h2>
          <p className="modal_content">ARE YOU OVER 21?</p>
          
          <div style={{display: 'flex', justifyContent:'center'}}>
            <StyledButton variant="contained" color="error" size="large" onClick={handleNo} style={{ marginRight: '8px'}}>
              No
            </StyledButton>
            <StyledButton variant="contained" color="success" size="large" onClick={(handleYes)}>
              Yes
            </StyledButton>
          </div>
         </div>
        </div>
        </Modal>
  );
}

const StyledButton = styled(Button)`
  margin: 10px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;

  &.MuiButton-containedError {
    background-color: #DC3545;
  }

  &.MuiButton-containedSuccess {
    background-color: #198754;
  }
`;