import React, { useState, useLayoutEffect, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';
import { Suspense } from 'react';
import Model from './top-cola-v3.glb';
import { OrbitControls } from '@react-three/drei';


import './Pinnacle.scss';



function ModelScene() {
    const gltf = useLoader(GLTFLoader, Model);
    gltf.scene.rotation.x = -(Math.PI / 2 + 100);
    gltf.scene.rotation.z = -(Math.PI / 2) + 100;

    const mesh = useRef();

    useFrame(() => {
        mesh.current.rotation.z += -0.00125; //rotate by X radians around axis per frame
    });

    return <primitive ref={mesh} object={gltf.scene} />

}



function MyScene() {
    // below this until return() is related to resizing
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        function updateSize() {
          const { clientWidth, clientHeight } = containerRef.current;
          setWidth(clientWidth);
          setHeight(clientHeight);
        }
    
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
      }, []);
     



    
    return (
        <div className="introWrapper">
            {/* <h1>TEST</h1> */}
        <div ref={containerRef}
        style={{ width: '100%', height: '100vh', position: 'relative' }}>        
            <Suspense fallback={<div>Loading...</div>}>
                    <Canvas style={{ position: 'absolute', top: 0, left: 0 }}
                gl={{ antialias: true }} camera={{ position: [0, 0, 2.75], fov: 50 }}>

                            <ambientLight intensity={.75} />
                            <pointLight
                                position={[0, 0, 0]}
                                color="white"
                                intensity={.75}
                                distance={100}
                                decay={2}
                            />
                            <ModelScene />
                            <OrbitControls enableZoom={false} />

                    </Canvas>
            </Suspense>
        </div>
        </div>
    )
}

export default MyScene;