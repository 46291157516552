import React, { useState, useEffect, useRef } from 'react';
import useFetch from '../../hooks/useFetch';
import './Card.scss';

// modal related stuff
import Strains from '../../pages/Product/Strains';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

// modal arrows prev/next
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '80%',
  maxHeight: '80vh',
  width: 'auto',
  height: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



const Card = ({ item }) => {
  const { data, loading, error } = useFetch(`strains/${item.id}?populate=*`);


  // modal stuff next 3 lines
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  return (
    <>
      <div className="card" onClick={handleOpen}>
        <div className="image moving-border">
          {item?.attributes?.isNew && <span className="dropped">Just Dropped</span>}
        
        <span className="IHS-span">
          {item?.attributes?.IHS}
        </span>
      
        <span className="cross">
              {data?.attributes?.Cross}
        </span>

          <img
            src={
              process.env.REACT_APP_UPLOAD_URL +
              item.attributes?.img?.data?.attributes?.url
            }
            alt=""
            className="mainImg"
          />

          <img
            src={
              process.env.REACT_APP_UPLOAD_URL +
              item.attributes?.img2?.data?.attributes?.url
            }
            alt=""
            className="secondImg"
          />
        </div>

        <h2>{item?.attributes?.Title}</h2>

        {/* <div className="cross">
        <h3>{data?.attributes?.Cross}</h3>
        </div> */}

        {/* <div className="breeder">
        <h3>By: {data?.attributes?.Breeder}</h3>
        </div> */}

      </div>




      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

      <Fade in={open}>
        <Box sx={style}>
          <div>
            <Strains id={item.id} /> 
            
            {/* <IconButton>
              <ArrowBackIcon />
            </IconButton>
                <IconButton>
                  <ArrowForwardIcon />
                </IconButton> */}
            
            {/* use strains.jsx to edit presentation */}
          </div>
        </Box>
      </Fade>
  </Modal>



    </>
  );
};

export default Card;
