import React from 'react';
import "./Form.scss";
import Form from "./Form.js";

function ContactForm() {
  return (

        <Form />

  );
}

export default ContactForm