import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import MyScene from "../360/Pinnacle";
import './Splash.scss';

import Dub from '../../assets/images/dub.png';
import Platez from '../../assets/images/platez.png';

const Splash = () => {
  

  return (
    
    <Wrapper>
        
        <MyScene />
                <div>
                    <img className="intro-dub" src={Dub} />
                    <img className="intro-platez" src={Platez} />
                </div>
        <span className="intro-desc">
            <div className="intro-effect">
                {/* <h3>VIP access to elite strains</h3> */}
                <h3>Elite cannabis cuts ahead</h3>
            </div>
        </span>
        {/* <Description>Get VIP access to our elite cannabis genetics</Description> */}
        <ScrollDown><h2>scroll down</h2></ScrollDown> 
    </Wrapper>
  );
};

const arrowMove = keyframes`
  to {
    transform: rotate(45deg) translateY(2rem) translateX(2rem);
    opacity: 0;
  }
`


const Wrapper = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
   overflow: hidden;
  background: linear-gradient(315deg, rgb(211, 0, 0) 3%, rgb(255, 110, 48) 38%, rgba(195, 0, 255, 0.35) 68%, rgba(88, 0, 230, 0.35) 98%);



    animation: gradient 12s ease infinite;
    
    background-size: 400% 400%;
    background-attachment: fixed;
    
    @keyframes gradient {
        0% {
            background-position: 0% 0%;
        }
        50% {
            background-position: 100% 100%;
        }
        100% {
            background-position: 0% 0%;
        }
    }
`;


// const Description = styled.p`
// position: absolute;
// margin-top: 3rem;

// // top: 35%;
// bottom: 10%;
// left: 50%;
// transform: translate(-50%, -50%);
// color: #fff;
// text-align: center;
// z-index: 1;

// padding: 10px;

//   font-size: 2.5rem;
//   border-style: dotted;
//   margin-bottom: 4rem;
//   text-transform: uppercase;
// `;



const ScrollDown = styled.div`
  position: fixed;
  z-index: 500;
  bottom: 5rem;
  transition: transform 0.5s 1.5s, opacity 0.3s 1.7s, z-index 0.5s 1.5s;
  font-size: 1.75rem;
  text-transform: uppercase;
  left: calc(50% - 15rem);
  color: #fff;
  width: 30rem;
  text-align: center;


  &:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -2.5rem;
  left: calc(50% - 1.25rem);
  width: 2.5rem;
  height: 2.5rem;
  border: 0.5rem solid #fff;
  border-left: none;
  border-top: none;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  animation: ${arrowMove} 1.2s infinite;

  margin-bottom: 5px;
}
`;



export default Splash;