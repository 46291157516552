import React from 'react';
import ContactForm from "../../components/Form/Form.jsx";



function Contact() {
  return (
<ContactForm/>
  )
}

export default Contact



