import React from 'react'
import "./List.scss"
import Card from "../Card/Card"
import useFetch from '../../hooks/useFetch'

import { Grid } from '@mui/material'

const List = ({ subCats, catId }) => {

    const { data, loading, error } = useFetch(
        `strains?populate=*${subCats.map(
            (item) => `${item}`
        )}`
    );

    return (
        // <div className="list">
        //     {loading
        //         ? "loading"
        //         : data?.map((item) => <Card item={item} key={item.id} />)}
        // </div>

        <Grid container spacing={2}>
        {loading
            ? "loading"
            : data?.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                    <Card item={item} />
                </Grid>
            ))}
    </Grid>


  );
};

export default List