import React, { useState } from "react";

const Form = () => {
    const [submit, setSubmit] = useState(false);
    const [formData, setFormData] = useState({
        "entry.2005620554": "", // name
        "entry.801461432": "", // email
        "entry.1166974658": "", // phone
        "entry.839337160": "", // farm/co
        "entry.12694202": "" // msg
    });

    const handleInputData = (input) => (e) => {
        const { value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [input]: value
        }));
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setSubmit(true);

        let url= `https://docs.google.com/forms/d/e/1FAIpQLSc62b1JgKuurqRwKQIEaGVW9YLcsQGoobnCl2u6kmRJDkaRnw/formResponse?entry.2005620554=${formData["entry.2005620554"]}&entry.801461432=${formData["entry.801461432"]}&entry.1166974658=${formData["entry.1166974658"]}&entry.839337160=${formData["entry.839337160"]}&entry.12694202=${formData["entry.12694202"]}`;

        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });

        setTimeout(() => {
            window.location.href = "/";
        }, 5000);
    }

    return (
        <div className="contactFormWrapper">
        <div className="container">
            <div className="title">
                <h1>Want to Know More?</h1>
            </div>
            <div className="formcontact">
                {submit ? (
                    <div className="afterForm"><h3>Thanks, we'll get back to you soon!</h3></div>
                ) : (
                    <form onSubmit={handleSubmit} target="_self">
                    <div className="userDetails">
                        <div className="inputBox">
                            <fieldset>
                                <label className="details" htmlFor="entry.2005620554">*Name:</label>
                                <input
                                    required
                                    type="text"
                                    name="entry.2005620554"
                                    onChange={handleInputData("entry.2005620554")}
                                    value={formData["entry.2005620554"]}
                                    autoComplete={false}
                                />
                            </fieldset>
                        </div>
                        <div className="inputBox">
                        <fieldset>
                            <label className="details" htmlFor="entry.801461432">*Email:</label>
                            <input
                                required
                                type="text"
                                name="entry.801461432"
                                onChange={handleInputData("entry.801461432")}
                                value={formData["entry.801461432"]}
                                autoComplete={false}
                            />
                        </fieldset></div>

                        <div className="inputBox">
                        <fieldset>
                            <label className="details" htmlFor="entry.1166974658">*Phone:</label>
                            <input
                                required
                                type="text"
                                name="entry.1166974658"
                                onChange={handleInputData("entry.1166974658")}
                                value={formData["entry.1166974658"]}
                                autoComplete={false}
                            />
                        </fieldset></div>

                        <div className="inputBox">
                        <fieldset>
                            <label className="details" htmlFor="entry.839337160">*Farm/Company:</label>
                            <input
                                required
                                type="text"
                                name="entry.839337160"
                                onChange={handleInputData("entry.839337160")}
                                value={formData["entry.839337160"]}
                                autoComplete={false}
                            />
                        </fieldset></div>

                        <div className="inputBox width100">
                        <fieldset>
                            <label className="details" htmlFor="entry.12694202">Message: (optional)</label>
                            <textarea className="messageInput"
                                type="text"
                                name="entry.12694202"
                                onChange={handleInputData("entry.12694202")}
                                value={formData["entry.12694202"]}
                                autoComplete={false}
                            ></textarea>
                        </fieldset></div>
                        
                <button className="button" type="submit">Connect</button>
                        </div>
                    </form>
                )}
            </div>
        </div>
        </div>
    );
};

export default Form;