import React from 'react';
import "./FeaturedStrains.scss";
import Card from '../Card/Card';
import useFetch from '../../hooks/useFetch';

import styled from 'styled-components';

import { Grid } from '@mui/material';

// const Wrapper = styled.div`
// display: flex;
// justify-content: center;
// align-items: center;
// max-width: 1200px;
// width: auto;
// margin: 0 auto;
// padding: 1rem;
// box-sizing: border-box;

// @media (max-width: 768px) {
//   flex-direction: column;
// }
// `;




const FeaturedStrains = ({type}) => {

const {data,loading,error} = useFetch(`/strains?populate=*&[filters][type][$eq]=${type}`);



  return (
    
    <div className="featuredStrains" id="featured">

<svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
  <defs>
    <radialGradient id="Gradient1" cx="50%" cy="50%" fx="10%" fy="50%" r=".5">
      <animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite" />
      <stop offset="0%" stop-color="#ff0" />
      <stop offset="100%" stop-color="#ff00" />
    </radialGradient>
    <radialGradient id="Gradient2" cx="50%" cy="50%" fx="10%" fy="50%" r=".5">
      <animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite" />
      <stop offset="0%" stop-color="#0ff" />
      <stop offset="100%" stop-color="#0ff0" />
    </radialGradient>
    <radialGradient id="Gradient3" cx="50%" cy="50%" fx="50%" fy="50%" r=".5">
      <animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite" />
      <stop offset="0%" stop-color="#f0f" />
      <stop offset="100%" stop-color="#f0f0" />
    </radialGradient>
  </defs>
  <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient1)">
    <animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite" />
    <animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite" />
    <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="17s" repeatCount="indefinite" />
  </rect>
  <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient2)">
    <animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite" />
    <animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite" />
    <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="18s" repeatCount="indefinite" />
  </rect>
  <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient3)">
    <animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite" />
    <animate attributeName="y" dur="26s" values="0%;25%;0%" repeatCount="indefinite" />
    <animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="19s" repeatCount="indefinite" />
  </rect>
</svg>







        <div className="top">
          
            <h1>Limited Presses</h1>
            <p>
            These won't last long.
            </p>
        </div>
       

       <Grid container spacing={2}>
        {error 
                ? "Something went wrong!" 
                : loading 
                ? "loading"
                : data?.map((item) => (
                  <Grid key={item.id} item xs={12} sm={6} md={4}>
                    <Card item={item}  /> 
                  </Grid>
                ))}
       </Grid>



       
       {/* <Wrapper> */}
        {/* <div className="bottom"> */}
            {/* {error 
                ? "Something went wrong!" 
                : loading 
                ? "loading"
                : data?.map((item) => <Card className="featuredCard" item={item} key={item.id} /> )} */}
        {/* </div> */}
{/* </Wrapper> */}

    </div>
  );
};

export default FeaturedStrains;


