import React from 'react'
import "./Home.scss"

import FeaturedStrains from '../../components/FeaturedStrains/FeaturedStrains'
import Category from '../Products/Category'
import MoreInfo from '../About/MoreInfo'

import AgeVerificationOverlay from '../../components/Ageverificationoverlay'
// import Splash from '../../components/Splash/Splash'
import Splash from '../../components/Splash/Splash.jsx'


// import ImageTrack from '../../components/ImageTrack/ImageTrack'
// import About from '../About/About'
// import Slider from '../../components/Slider/Slider'
// import Contact from '../../components/Contact/Contact'

import MyScene from '../../components/360/Pinnacle'




const Home = () => {

  
  return (
    <div className="home">
        
        <AgeVerificationOverlay/>
        <Splash/>
        <FeaturedStrains type="featured"/>
        {/* <Contact/> */}
        <Category/>
        {/* <MyScene /> */}

        {/* <About/> */}
        <MoreInfo/>
    </div>
  )
}

export default Home