import React from 'react'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import "./Strains.scss"
import "./Tabs"
import BasicTabs from './Tabs';

// import MagGlass from  "../../../assets/images/magnifying-glass.svg";
import TimeToHarvest from "../../assets/images/cut-icon.svg";
import mediumyield from "../../assets/images/medium-yield.svg";
import heavyyield from "../../assets/images/heavy-yield.svg";
import extraheavyyield from "../../assets/images/extra-heavy-yield.svg";


// mui stuff
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import ButtonBase from '@mui/material/ButtonBase';
import { Button } from '@mui/material';



// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

//






const Strains = ({id} ) => {
  const [selectedImg,setSelectedImg] = useState("img");
  const [quantity, setQuantity] = useState(1);

  const { data, loading, error } = useFetch(`strains/${id}?populate=*`);

  return (
    <Box sx={{ flexGrow: 1, margin: "-30px", border: "none", overflow: "hidden"}} >
      <div className="modalCard">
          <Grid container spacing={2}> 

            {/*  Main image and image selection area  */}
            <Grid item xs={12} md={8} container>
              <Grid item xs={2} container direction="column">
                <ButtonBase>
                  <Img src={process.env.REACT_APP_UPLOAD_URL + data?.attributes?.img?.data?.attributes?.url} alt="" onClick={(e) => setSelectedImg("img")}/>
                </ButtonBase>

                <ButtonBase>
                  <Img src={process.env.REACT_APP_UPLOAD_URL + data?.attributes?.img2?.data?.attributes?.url} alt="" onClick={(e) => setSelectedImg("img2")}/>
                </ButtonBase>
              </Grid>

              <Grid item xs={10}>
                <Img className="selectedImg" src={process.env.REACT_APP_UPLOAD_URL + data?.attributes[selectedImg]?.data?.attributes?.url} 
                alt=""/>
              </Grid>
            </Grid>

            {/* Info and text area */}
            <Grid item xs={12} md={4} container>
              <Grid item xs={12} md={12} lg={12}>
                <div className="cardHeader">
                  <span class="cardTitle">{data?.attributes?.Title}</span>
                  
                  <p className="cardDetails-cross">{data?.attributes?.Cross}</p>
                  <p className="cardDetails-breeder">By {data?.attributes?.Breeder}</p>
                  <h2 className="thc">{data?.attributes?.thc}% THC</h2>
                  
                  <span className="IHS-span">
                  {data?.attributes?.IHS}
                   </span>

                </div>

                <div className="cardDetails">
                  
                  {/* <p className="cardDetails-yield">Yield: {data?.attributes?.Yield}</p> */}
                  <div className="cardDetails-yield"> 
                    {data?.attributes?.Yield === "Medium" && <img class="yield-icon" src={mediumyield}></img>}
                    {data?.attributes?.Yield === "Heavy" && <img class="yield-icon" src={heavyyield}></img>}
                    {data?.attributes?.Yield === "Super Heavy" && <img class="yield-icon" src={extraheavyyield}></img>}
                  </div>

                
                    <div className="tth">
                      <img class="tth-icon" src={TimeToHarvest} alt="time to harvest"></img>
                      <p className="cardDetails-harvesttime">
                        {data?.attributes?.HarvestWindow} days</p>
                    </div>





                </div>


                <div className="tabArea">
                  <Grid item xs={12} md={12} lg={12}>
                    <BasicTabs data={data}></BasicTabs>
                  </Grid>
                </div>


              </Grid>


            </Grid>

          </Grid>
      </div>
    </Box>
  );

};

export default Strains;