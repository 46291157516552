import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styled from "styled-components";

import Typography from '@mui/material/Typography';
import backgroundImg from "./aboutus1.png";

import "./MoreInfo.scss";
import about1 from "./aboutus1.png";
import about2 from "./aboutus2.png";
import about3 from "./aboutus3.png";




const RootContainer = styled(Grid)`
background-image: url(${backgroundImg});
background-size: cover;
background-position: center;
width  : 100vw;
height: 50vh;
`;

function MoreInfo() {
  return (
    <div className="mi-wrapper">
        <div className="mi-inner-wrapper">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={4} md={4}>
                        <img className="aboutPic" src={about2}></img>
                    </Grid>
                    
                    <Grid item xs={8} md={8}>
                        <h2>premium cannabis strains</h2>
                        
                        <ul>
                            <li>
                            High frequency dub vibrations
                            </li>
                            <li>
                            THC experimentation
                            </li>
                            <li>
                            Elevate your experience
                            </li>
                            <li>
                            The best of the best
                            </li>
                            <li>
                            Unrivaled strain selection
                            </li>
                        </ul>
                    </Grid>
                    

                    {/* <Grid item xs={8}>
                        <h2>If you know... you know</h2>
                        <p>Join the exclusive club of cannabis connoisseurs with Dubplatez. Elevate your cannabis game with our limited edition strains and experience a cannabis journey like no other. If you’re on the vibe, then you know where to find us.</p>
                    </Grid>
                    <Grid item xs={4}>
                        <img className="aboutPic" src={about1}></img>
                    </Grid> */}



                        <RootContainer className="iykyk" item xs={12}>
                            <h2>If you know... you know</h2>

                            <p>Join the exclusive club of cannabis connoisseurs and elevate your cannabis game with our limited edition strains</p> 
                            
                            <p>UNLEASH THE POWER OF THE GENETICS</p>
                        </RootContainer>




                </Grid>
            </Box>
        </div>
    </div>



  );
};

export default MoreInfo;