import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import List from '../../components/List/List';
import useFetch from '../../hooks/useFetch';
import "./Category.scss";

import logo from './availablestrainsbanner.png';

import headerSmall from './banner-sm.jpg';
import headerLarge from './banner-lg.jpg';

const Category = () => {
  const catId = parseInt(useParams().id);

  const [selectedSubCats, setSelectedSubCats] = useState([]);

  const {data, loading, error} = useFetch(`strains?[strainType][$eq]=${catId}`
  );

  const handleChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedSubCats(
      isChecked
      ? [...selectedSubCats, value]
      : selectedSubCats.filter((item) => item !== value)
    );
  };

  console.log(selectedSubCats)
  

  const [logo, setLogo] = useState('');
  useEffect(() => {
    if (window.innerWidth >= 768) {
      setLogo(headerLarge);
    } else {
      setLogo(headerSmall);
    }

    // update when screen changes
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setLogo(headerLarge);
    } else {
      setLogo(headerSmall);
    }
  };

  return (
    <div className="categoryWrapper">
        
        <div className="headerDistortion">
          <img
            className="catImg"
            src={logo}
            alt="" />
          <img
            className="catImg"
            src={logo}
            alt="" />


<span>Fresh Cuts</span>


          <svg>
            <filter id="transmissionerror">
              <feColorMatrix type="saturate" values="0" in="SourceGraphic" result="colormatrix1" />
              <feColorMatrix type="matrix" values="0.95 0 0 0 0  0 0 0 0 0  0 0 0.4 0 0  0 0 0 1 0" in="colormatrix1" result="colormatrix2" />
              <feTurbulence type="turbulence" baseFrequency="0.002 0.008" numOctaves="2" seed="5" stitchTiles="noStitch" result="turbulence">
                <animate attributeName="baseFrequency" from="0.002 0.008" to="0.002 0.01" dur="3500ms" repeatCount="indefinite" />
                <animate attributeName="numOctaves" from="1" to="10" dur="750ms" repeatCount="indefinite" />
                <animate attributeName="seed" from="1" to="100" dur="10s" repeatCount="indefinite" />
              </feTurbulence>
              <feColorMatrix type="saturate" values="30" in="turbulence" result="colormatrix3" />
              <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 100 -15" in="colormatrix3" result="colormatrix4" />
              <feDisplacementMap in="colormatrix2" in2="colomatrix4" scale="15" xChannelSelector="R" yChannelSelector="A" result="displacementMap" />
            </filter>
          </svg>
        </div>



          <List class="list-items" catId={catId} subCats={selectedSubCats}/>
      

          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>     

      
    </div>
  )
}

export default Category