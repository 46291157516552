import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styled from "styled-components";

import "./About.scss";
// import logo from "./main_logo.png";
import about1 from "./aboutus1.png";
import about2 from "./aboutus2.png";
import about3 from "./aboutus3.png";

import logo from '../../assets/images/main_logo.svg';

const Logo = styled.img`

`;




const About = () => {
    return (
<div className="wrapper">
  <div className="innerWrapper">
      <Box sx={{ flexGrow: 1 }}>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid className="left" item xs={12} md={8}>
          <h2>Invite Only</h2>

          <hr/>
         <p>Welcome to Dubplatez, where only the most discerning cannabis cultivators have access to our exclusive, limited edition strains.</p>
          
           {/* <p>We create the most sensational terpene profiles and highest potency strains available, ensuring that every cultivator who grows our strains is a cut above the rest.</p>  */}
           
           <p>If you're here, you already know the vibes and have what it takes to join our exclusive club of cannabis connoisseurs.</p> 
           
           <p>So why wait? Elevate your cannabis game with Dubplatez</p>
        </Grid>
        <Grid className="right" item xs={8} md={4}>
         <figure>
         <div class="stripes-wrapper">
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
             <div class="stripe"></div>
         </div>

         <div class="gradient-mask"></div>
         <div class="logo"><Logo src={logo} alt="Dubplatez" /></div>
         {/* <div class="logo"></div> */}
        
         </figure>
         
        </Grid>

{/*         
        <Grid item xs={4}>
            <img className="aboutPic" src={about2}></img>
        </Grid>
        <Grid item xs={8}>
          <h2>Unleash the power of our premium cannabis strains</h2>
          <ul>
            <li>
              High frequency dub vibrations
            </li>
            <li>
              THC experimentation
            </li>
            <li>
              Elevate your experience
            </li>
            <li>
              The best of the best
            </li>
            <li>
              Unrivaled strain selection
            </li>
          </ul>
        </Grid>
        <Grid item xs={8}>
          <h2>If you know... you know</h2>
         <p>Join the exclusive club of cannabis connoisseurs with Dubplatez. Elevate your cannabis game with our limited edition strains and experience a cannabis journey like no other. If you’re on the vibe, then you know where to find us.</p>
        </Grid>
        <Grid item xs={4}>
            <img className="aboutPic" src={about1}></img>
        </Grid> */}







      </Grid>
    </Box>





  </div>
</div>
//     <div>
//       <div className="container-top">
//         <div className="video-container-top">
//           <video autoPlay loop muted className="video">
//             <source src={`${process.env.PUBLIC_URL}/360-big-black.mp4`} type="video/mp4" />
//           </video>
//         </div>
//         <div className="text-container-top">
//           <h2>access the best strains before anyone else</h2>
//                       <hr></hr>

//           <p>Join the exclusive club of cannabis connoisseurs with Dubplatez.</p> 
//             <p>Elevate your cannabis game with our limited edition strains and experience a cannabis journey like no other.</p> 
            
//             <h3>If you're on the vibe, then you know where to find us.</h3>
//         </div>
//       </div>

//       <div className="container-bottom">
    
//       <div className="text-container-bottom">
//         <h2>be the first to grow our top-shelf cannabis strains
// </h2>
//                     <hr></hr>

//         <p>Join the exclusive club of cannabis connoisseurs with Dubplatez.</p> 
//           <p>Welcome to the world of Dubplatez, where only the most discerning cannabis cultivators have access to our exclusive, limited edition strains. Our community of expert cannabis breeders is dedicated to creating the most sensational terpene profiles and highest potency strains available, ensuring that every cultivator who grows our strains is a cut above the rest. If you're here, you already know the vibes and have what it takes to join our exclusive club of cannabis connoisseurs. So why wait? Take the first step towards greatness and elevate your cannabis game with Dubplatez.</p> 
          
//       </div>

//       <div className="video-container-bottom">

//         <figure>
//         <div class="stripes-wrapper">
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//             <div class="stripe"></div>
//         </div>

//         <div class="gradient-mask"></div>
//         <div class="logo"></div>
        
//         </figure>
//       </div>



//     </div>      
//   </div>


    );
  };
  
  export default About;