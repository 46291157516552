import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
{
	title: "Who We Are",
	path: "/about-us",
	icon: <AiIcons.AiFillHome />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,
},
{
	title: "Current Library",
	path: "/strains",
	icon: <IoIcons.IoIosPaper />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,

	// subNav: [
	// {
	// 	title: "Hybrid",
	// 	path: "/strains/hybrid",
	// 	icon: <IoIcons.IoIosPaper />,
	// 	cName: "sub-nav",
	// },
	// {
	// 	title: "Indica",
	// 	path: "/strains/indica",
	// 	icon: <IoIcons.IoIosPaper />,
	// 	cName: "sub-nav",
	// },
	// {
	// 	title: "Sativa",
	// 	path: "/strains/sativa",
	// 	icon: <IoIcons.IoIosPaper />,
	// },
	// ],
},
{
	title: "Get in Touch",
	path: "/contact",
	icon: <FaIcons.FaPhone />,
},

];
