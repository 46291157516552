// import {
//   createBrowserRouter,
//   RouterProvider,
//   Outlet
// } from "react-router-dom";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";

import Sidebar from "./components/Sidebar/Sidebar";


import Home from "./pages/Home/Home";
import Category from "./pages/Products/Category";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";


import Strains from "./pages/Product/Strains";

import "./app.scss"

function HomePage() {
  return (
  <div>
    <Home />
  </div>
  );
}




function App() {
  return (
    <Router>
      <Sidebar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about-us' element={<About/>} />
        <Route path='/strains' element={<Category/>} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>
    </Router>

    
  )
}

export default App;


// const Layout = () =>{
//   return (
//     <div className="app">
//       <Navbar/>
//       <Outlet/>
//       {/* <Footer/> */}
//     </div>
//   )
// }

// const router = createBrowserRouter([
//   {
//     path:"/",
//     element:<Layout/>,
//     children: [
//       {
//         path:"/",
//         element:<Home/>
//       },
//       {
//         path:"/category/:id",
//         element:<Category/>
//       }
//     ]
//   },
// ])

// function App() {
//   return (
//     <div>
//       <RouterProvider router={router} />
//     </div>
//   );
// }

// export default App;
