import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SideBarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

import logo from '../../assets/images/main_logo.svg';

//below is the color of navbar itself, change to something better
const Nav = styled.div`
  background: #d93525;
  box-shadow: 0px -20px 15px -15px rgba(0,0,0,0.45) inset,
  0px 20px 15px -15px rgba(0,0,0,0.45);
  
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: ${({ isHidden }) => (isHidden ? "-80px" : "0")};
  width: 100%;
  transition: top 0.1s ease-in-out;
  z-index: 121;

`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;


const NavBrand = styled(Link)`
  margin-left: 1rem;
  `;

const Logo = styled.img`
  height: 4rem;
  fill: blue;
  `;

// const NavBrand = styled(Link)`
//   color: green;
//   font-size: 1.5rem;
//   margin-left: 1rem;
//   text-decoration: none;
// `;

const SidebarNav = styled.nav`
  background: #15171c;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 130;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Overlay = styled.div`
    position: fixed;
    z-index: ${({ sidebar }) => (sidebar ? "20" : "-1")};
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ sidebar }) => (sidebar ? "1" : "0")};
    pointer-events: ${({ sidebar }) => (sidebar ? "auto" : "none")};
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
 
  const showSidebar = () => setSidebar(!sidebar);

  // hide sidebar on click below
//   const sidebarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      sidebar
    ) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (prevScrollPos > currentScrollPos && isHidden) {
        setIsHidden(false);
      } else if (prevScrollPos < currentScrollPos && !isHidden) {
        if (currentScrollPos > 100) {
          setIsHidden(true);
        }
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, isHidden]);


  // handling outside sidebar click to close
//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutsideSidebar);
//     return () =>
//     document.removeEventListener("mousedown", handleClickOutsideSidebar);
//   }, []);
const sidebarRef = useRef();
useEffect(() => {
    const handleOutsideClick = (e) => {
        if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
            setSidebar(false);
        }
    };

  document.addEventListener("mousedown", handleOutsideClick);
  return () => document.removeEventListener("mousedown", handleOutsideClick);
}, [sidebarRef]);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav isHidden={isHidden}>
          <NavIcon to="#">
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
          <NavBrand to="/">
            <Logo src={logo} alt="Dubplatez" />
          </NavBrand>
        </Nav>
        <SidebarNav sidebar={sidebar} ref={sidebarRef}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>

      {/* {sidebar && (
        <Overlay onClick={() => {
            setSidebar(false);
        }}
        />
      )}       */}
    </>
  );
};

export default Sidebar;



